/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import Article from '../../components/article/Article';
import './blog.css';
import placeholderImage from '../../assets/blog03.png';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch('https://socialpreneur.co.ke/wp-json/wp/v2/posts?_embed&per_page=5');
        const data = await response.json();
        setBlogPosts(data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };

    fetchBlogPosts();
  }, []);

  // Function to strip HTML tags
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };

  return (
    <div className="gpt3__blog section__padding" id="blog">
      <div className="gpt3__blog-heading">
        <h1 className="gradient__text">We aim to inspire through the art of storytelling, <br /> Keeping you informed with the latest news on development and industry trends.</h1>
      </div>
      <div className="gpt3__blog-container">
        <div className="gpt3__blog-container_groupA">
          {blogPosts.slice(0, 1).map((post) => (
            <a key={post.id} href={post.link} className="gpt3__blog-link" target="_blank" rel="noopener noreferrer">
              <Article
                imgUrl={post._embedded?.['wp:featuredmedia']?.[0]?.source_url || placeholderImage}
                date={new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                title={post.title.rendered}
                text={stripHtmlTags(post.excerpt.rendered)}
                link={post.link}
              />
            </a>
          ))}
        </div>
        <div className="gpt3__blog-container_groupB">
          {blogPosts.slice(1, 5).map((post) => (
            <a key={post.id} href={post.link} className="gpt3__blog-link" target="_blank" rel="noopener noreferrer">
              <Article
                imgUrl={post._embedded?.['wp:featuredmedia']?.[0]?.source_url || placeholderImage}
                date={new Date(post.date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                title={post.title.rendered}
                link={post.link}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
