/* eslint-disable react/jsx-props-no-spreading */
// Brand.jsx
import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { vital, bath, bloomberg, circleg } from './imports';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './brand.css';

const Brand = () => {
  const logos = [vital, bath, bloomberg, circleg, vital, bath, bloomberg, circleg];
  const sliderRef = useRef(null);

  function calculateSlidesToShow() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 500) {
      return 1;
    } if (screenWidth < 768) {
      return 2;
    }
    return 3;
  }

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, []);

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: calculateSlidesToShow(),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    variableWidth: true, // Set variableWidth to true
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 9999,
        settings: {
          slidesToShow: calculateSlidesToShow(),
        },
      },
    ],
  };

  return (
    <div className="gpt3__brand section__padding gpt3__whatgpt3" id="brands">
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">Support you can trust and rely on.</h1>
        <p>Over the Years, we have worked with multiple reputable brands. Delivering inspiring work and meeting and exceeding expectations. Below are just a few of them. Click the link to view our vast Portfolio.</p>
      </div>
      <div className="gpt3__brand-btn">
        <button type="button"><a href="/projects" target="_blank" rel="noopener noreferrer">Our Portfolio</a></button>
      </div>
      <Slider ref={sliderRef} {...sliderSettings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-container">
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Brand;
