/* eslint-disable max-len */
// Projects.jsx
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
// import Navbar from '../../components/navbar/Navbar';
import Contact from '../../components/contact/contact';
import Footer from '../footer/Footer';
import bath from '../../assets/bath.jpeg';
import vital from '../../assets/vital.jpeg';
import circleg from '../../assets/circleg.png';
import Modal from './ProjectModal'; // Import the Modal component
import './projects.css';

const Projects = () => {
  useEffect(() => {
    document.title = 'Our Portfolio | Surety PR & Media';
  }, []);

  const projectsData = [
    {
      companyLogo: vital,
      title: 'Journalist trainings & Stakeholder Engagements | Vital Strategies',
      description: 'Throughout the past year, Surety PR & Media has actively engaged in collaborative efforts with Bloomberg Philanthropies global road safety initiative, facilitated by Vital Strategies. Our involvement spans journalist and stakeholder workshops, as well as providing photography, videography, and other media-related services."',
      videoUrl: 'https://www.youtube.com/embed/C52BrBoH0l4',
      additionalParagraph: 'Our commitment extends to ongoing communication and administrative support for this noble cause in Mombasa. At Surety PR & Media, our steadfast dedication remains focused on aiding both local and international NGOs in advancing societal development, improving communities, and encouraging citizen participation.',
    },
    {
      companyLogo: circleg,
      title: 'Express Your Beauty - Interview with Kevin Ismael | Circleg',
      description: 'In 2022, Surety PR & Media collaborated with Circleg, a Swiss-East African Social Enterprise, to launch the "Express Your Beauty" campaign, a market entry initiative. This empowering project aimed to uplift amputees, providing them a platform to appreciate themselves and amplify their voices.',
      videoUrl: 'https://www.youtube.com/embed/UYv-CD4qcdk',
      additionalParagraph: 'The campaign`s core mission was to empower amputees, fostering confidence and dispelling societal stigmas associated with amputation. Surety PR & Media spearheaded the entire production and coordination process, ensuring the successful realization of this impactful project This collaboration exemplifies the power of media in driving social change and fostering inclusivity..',
    },
    {
      companyLogo: circleg,
      title: 'Express Your Beauty - Interview with Kevin Ismael | Circleg',
      description: 'In 2022, Surety PR & Media collaborated with Circleg, a Swiss-East African Social Enterprise, to launch the "Express Your Beauty" campaign, a market entry initiative. This empowering project aimed to uplift amputees, providing them a platform to appreciate themselves and amplify their voices.',
      videoUrl: 'https://www.youtube.com/embed/Xo9esaihPHw',
      additionalParagraph: 'The campaign`s core mission was to empower amputees, fostering confidence and dispelling societal stigmas associated with amputation. Surety PR & Media spearheaded the entire production and coordination process, ensuring the successful realization of this impactful project This collaboration exemplifies the power of media in driving social change and fostering inclusivity.',
    },
    {
      companyLogo: bath,
      title: 'Tobacco Slave Film | University of Bath',
      description: 'In 2023, Surety PR & Media embarked on a significant collaboration with the Tobacco Control Research Group at the University of Bath, United Kingdom, a key partner in STOP. Joined by award-winning filmmakers Roy Maconachie and videographer Simon Wharf from the University of Bath, our proficient production team united to create the impactful documentary, "The Tobacco Slave."',
      videoUrl: 'https://www.youtube.com/embed/BuqA2xAusso',
      additionalParagraph: 'In 2023, Surety PR & Media partnered with the University of Bath`s Tobacco Control Research Group for the STOP initiative. Together, we created the impactful documentary "The Tobacco Slave" with filmmakers from the University. The documentary, shown in New York City, sheds light on the challenges faced by tobacco farmers in Malawi.',
    },
  ];

  // State to manage modal visibility and selected project
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  // Function to open the modal and set the selected project
  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  return (
    <div className="projects gradient__bg" id="projects">
      {/* <Navbar /> */}
      <header className="projects-header gpt3__possibility section__padding">
        <div className="gpt3__Projects-content">
          <h4 className="gradient__text">Contact Us Today to Make Your Vision a Reality</h4>
          <h1>Our Portfolio</h1>
        </div>
      </header>
      <div className="projects-cards">
        {projectsData.map((project, index) => (
          <div key={index} className="project-card" onClick={() => openModal(project)}>
            <iframe
              title={`Project Video ${index + 1}`}
              src={project.videoUrl}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              className="video-frame"
            />
            <div className="project-info">
              <img src={project.companyLogo} alt={`Company Logo ${index + 1}`} />
              <h1 className="gradient__text">{project.title}</h1>
              <p>{project.description}</p>
            </div>
          </div>
        ))}
      </div>
      <Contact />
      <Footer />

      {/* Modal component */}
      {isModalOpen && selectedProject && (
        <Modal project={selectedProject} closeModal={closeModal}>
          {/* Modal content goes here */}
        </Modal>
      )}
    </div>
  );
};

export default Projects;
