import React from 'react';
import possibilityImage from '../../assets/Team_2.webp';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Contact Us Today to Get Started</h4>
      <h1 className="gradient__text">Ready to transform your experience?  <br /> Join us at Surety PR & Media on a journey where cutting-edge innovation meets your imagination.</h1>
      <p>Let`s craft compelling stories, reshape narratives, and amplify your voice across the diverse landscapes of Africa.</p>
    </div>
  </div>
);

export default Possibility;
