/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedin, faInstagram, faWhatsapp, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
// import logo from '../../assets/surety_logo.png';
import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Cutting Edge Experience!</h1>
    </div>

    {/* <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={logo} alt="gpt3_logo" />
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Important Links</h4>
        <p>Link 1</p>
        <p>Link 2</p>
        <p>Link 3</p>
        <p>Link 4</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>Muthangari Road, Lavington</p>
        <p>+254 790 546 287</p>
        <p>info@suretymedia.com</p>
      </div>
    </div> */}

    <div className="gpt3__footer-social-icons">
      <a href="https://www.facebook.com/Suretyprmedia/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a href="https://www.linkedin.com/company/surety-pr-media-ltd/about/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a href="https://www.instagram.com/suretyprmedia/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href="https://wa.me/+254790546287" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
      <a href="https://twitter.com/PrSurety" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
      <a href="https://www.youtube.com/@SuretyPRMedia" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faYoutube} />
      </a>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2024 | Surety PR & Media | All rights reserved <br /> Designed and Developed by <a href="https://www.linkedin.com/in/e-sigei/" target="_blank" rel="noreferrer">Emmanuel Sigei</a></p>
    </div>
  </div>
);

export default Footer;
