import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Added Routes and Route
import GA from 'react-ga';
import * as Analytics from '@vercel/analytics';
import { Footer, Blog, Possibility, Features, WhatGPT3, Team, Header, Projects } from './containers';
import { CTA, Navbar, Contact, Brand } from './components';
import Gallery from './containers/gallery/gallery'; // Import the Gallery component
import './App.css';

const GA_TRACKING_ID = 'G-XV6G77EE0X'; // Replace with your Google Analytics tracking ID
const VERCEL_PROJECT_ID = 'prj_2ozRnQioeeEA4n4zjPGyR5HM3KFG'; // Replace with your Vercel project ID

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics
    GA.initialize(GA_TRACKING_ID);
    if (!GA.gaInitialized) {
      console.error('Error initializing Google Analytics');
    }
  }, []);

  const trackPageView = (path) => {
    GA.pageview(path);
    Analytics.track('Page View', {
      path,
      projectId: VERCEL_PROJECT_ID, // Include Vercel project ID here
    });
  };

  return (
    <Router>
      <div className="App">
        <div className="gradient__bg">
          <Navbar />
        </div>
        {/* Define Routes for navigation */}
        <Routes>
          <Route
            path="/"
            element={(
              <>
                <Header />
                <WhatGPT3 />
                <Features />
                <div className="gradient__bg_alt">
                  <Possibility />
                </div>
                <Team />
                <CTA />
                <Brand />
                <Blog />
                <Contact />
                <Footer />
              </>
            )}
          />
          <Route
            path="/projects"
            element={<Projects className="App" />}
            onEnter={() => trackPageView('/projects')}
          />
          <Route
            path="/gallery"
            element={<Gallery />}
            onEnter={() => trackPageView('/gallery')}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
