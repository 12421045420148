/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slider from 'react-slick';
import TeamCard from './TeamCard';
// import KevinImage from '../../assets/Kevin_Ismael_Resized_Final.webp';
// import Angel from '../../assets/Angel_Resized_Final.webp';
import Manu from '../../assets/Emmanuel_Resized_Final.webp';
import Nyambura from '../../assets/Nyambura_Resized_Final.webp';
// import June from '../../assets/June_Resized.webp';
// import Teddy from '../../assets/Teddy_Resized_Final.webp';
import Adam from '../../assets/Adam_Kagema.webp';
import Erica from '../../assets/Erica.webp';
import imani from '../../assets/Imani_Resized.webp';
// import Maryann from '../../assets/Maryann.webp';
// import Andre from '../../assets/Andre.webp';
import './team.css';

const Team = () => {
  const teamMembers = [
    // { name: 'Kevin Ismael', role: 'Chief Strategist', image: KevinImage },
    { name: 'Nyambura Nguru', role: 'HR & Admin', image: Nyambura },
    { name: 'Emmanuel Sigei', role: 'Tech Lead', image: Manu },
    // { name: 'Teddy Muraya', role: 'Creative Director', image: Teddy },
    // { name: 'June Mwende', role: 'Digital Strategist', image: June },
    // { name: 'Maryann Mkanjala', role: 'Digital Strategist', image: Maryann },
    { name: 'Kagema Mwangi', role: 'Creative Director', image: Adam },
    // { name: 'Sammie Andre', role: 'Design & Brand Strategist', image: Andre },
    { name: 'Erica Kweyu', role: 'Finance & Admin', image: Erica },
    { name: 'Nyaruai Mwangi', role: 'Public Relations Officer', image: imani },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5500,
    responsive: [
      {
        breakpoint: 767, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024, // Breakpoint for tablets
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 9999, // Breakpoint for desktop
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <div className="gpt3__whatgpt3 section__margin" id="team">
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">Our Star Studded Team.</h1>
        <p>We connect you with the world: Our team is dedicated to building strong relationships through effective communication and storytelling.</p>
      </div>
      <Slider {...sliderSettings}>
        {teamMembers.map((member, index) => (
          <TeamCard key={index} {...member} />
        ))}
      </Slider>
    </div>
  );
};

export default Team;
