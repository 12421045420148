import React from 'react';
// import people from '../../assets/people.png';
import team from '../../assets/header_graphic.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Your imagination <br /> can become a <br /> Reality.</h1>
      <p>Welcome to Surety PR & Media – where Cutting Edge Experience meets boundless imagination. As a leading Pan-African media company, we specialize in turning your visions into reality. <br /> Explore the forefront of innovation with us, as we redefine media excellence across the African continent</p>
      <div className="gpt3__header-btn">
        <button type="button"><a href="#about_us">Learn More</a></button>
      </div>
      {/* <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get Started</button>
      </div> */}

      {/* <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div> */}
    </div>

    <div className="gpt3__header-image">
      <img src={team} />
    </div>
  </div>
);

export default Header;
