/* eslint-disable react/jsx-indent */
import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <><div className="gpt3__whatgpt3 section__margin" id="about_us">
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="About Us"
        text={(
          <>
            At Surety PR & Media, we embody the essence of innovation, where our
            Cutting-Edge Experience seamlessly intertwines with a commitment to turning
            imagination into reality. As a leading Pan-African media company, our mission is to redefine the
            landscape of storytelling.{' '}
            <br />
            We thrive on the belief that every narrative is unique, and we dedicate
            ourselves to amplifying voices, shaping perceptions, and
            fostering creativity across the diverse and vibrant tapestry of Africa. With a
            passion for pushing boundaries and a relentless pursuit of excellence, we
            stand as your trusted partner in navigating the ever-evolving world of media,
            delivering impactful and transformative experiences that resonate across
            borders.
          </>
        )}
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">We have the expertise to Take your brand to the next level</h1>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Our Mission" text="We strive to offer Unique solutions and a cutting-edge experience to our clientele." />
      <Feature title="Our Vision" text="To redefine the PR & communications industry through Innovation, people centred solutions and constant consumer engagement." />
      <Feature title="Our Promise" text="At Surety PR & Media, our promise is to elevate your brand with unwavering integrity, innovative solutions, and a client-centric approach, ensuring transformative experiences that transcend borders and redefine the narrative landscape of Africa." />
    </div>
    </div>

    <div className="gpt3__whatgpt3 section__margin">
      <div className="gpt3__whatgpt3-heading_alt">
        <h1 className="gradient__text">Built on a foundation of core values that guide our every endeavor, shaping our identity and defining our commitment to excellence</h1>
      </div>
      <div className="gpt3__whatgpt3-feature">
        <Feature
          title="Our Values"
          text={(
            <>
              <ol>
                <p><li><strong>Innovation:</strong> We constantly seek innovative solutions to meet the ever-changing needs of our clients and audiences.</li></p>
                <p><li><strong>Integrity:</strong> Our reputation is built on a foundation of unwavering integrity.</li></p>
                <p><li><strong>Collaboration:</strong> We believe in the power of collaboration. By working seamlessly as a team internally and in partnership with our clients, we harness collective strengths to achieve remarkable outcomes. Together, we amplify the impact of our shared vision.</li></p>
                <p><li><strong>Diversity and Inclusion:</strong> We are committed to creating an environment where varied perspectives are valued, fostering a tapestry of voices that reflects the true essence of our continent.</li></p>
                <p><li><strong>Client-Centric Approach:</strong> We are dedicated to understanding their aspirations, providing personalized solutions, and delivering results that exceed expectations. Your success is our success.</li></p>
                <p><li><strong>Adaptability:</strong> We embrace change, stay ahead of industry trends, and remain agile in our approach to meet the evolving needs of our clients and the ever-shifting media landscape.</li></p>
                <p><li><strong>Excellence:</strong> From strategy to execution, we strive for the highest quality, setting benchmarks for ourselves and the industry. Our pursuit of excellence is relentless.</li></p>
              </ol>
            </>
          )}
        />
      </div>
    </div>
  </>
);

export default WhatGPT3;
