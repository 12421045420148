import React, { useEffect } from 'react';
import './gallery.css';
import Contact from '../../components/contact/contact';
import Footer from '../footer/Footer';

const projects = [
  { id: 1, title: 'Project 1', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 2, title: 'Project 2', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 3, title: 'Project 3', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 4, title: 'Project 4', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 5, title: 'Project 5', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 6, title: 'Project 6', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 7, title: 'Project 7', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 8, title: 'Project 8', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 9, title: 'Project 9', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 10, title: 'Project 10', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 11, title: 'Project 11', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 12, title: 'Project 12', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 13, title: 'Project 13', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 14, title: 'Project 14', imgUrl: 'https://via.placeholder.com/400x300' },
  { id: 15, title: 'Project 15', imgUrl: 'https://via.placeholder.com/400x300' },
];

const gallery = () => {
  useEffect(() => {
    document.title = 'Gallery | Surety PR & Media';
  }, []);

  return (
    <div>
      <div className="gallery-container">
        {projects.map((project) => (
          <div key={project.id} className="gallery-item">
            <img src={project.imgUrl} alt={project.title} className="gallery-img" />
            <div className="gallery-item-info">
              <h3>{project.title}</h3>
            </div>
          </div>
        ))}
      </div>

      <Contact />
      <Footer />
    </div>
  );
};

export default gallery;
