import React from 'react';
import './team.css';

const TeamCard = ({ name, role, image }) => (
  <div className="team-card">
    <img src={image} alt={name} />
    <div className="team-card-text">
      <h3 className="gradient__text">{name}</h3>
      <p>{role}</p>
    </div>
  </div>
);

export default TeamCard;
