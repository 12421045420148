/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';

const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        'service_ufypiu2',
        'template_bb3tvsf',
        {
          from_name: form.name,
          to_name: 'Surety PR & Media',
          from_email: form.email,
          to_email: 'info@suretymedia.com',
          message: form.message,
        },
        'Xhgy-sfqZ0BOBaGJ3',
      )
      .then(
        () => {
          setLoading(false);
          alert('Thank you. We will get back to you as soon as possible.');

          setForm({
            name: '',
            email: '',
            message: '',
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Ahh, something went wrong. Don't fret though, it is not your fault. Please try again shortly.");
        },
      );
  };

  return (
    <div className="gpt3__possibility_contact-section section__padding" id="possibility">
      <div className="gpt3__possibility-content_contact">
        <h4>Contact Information</h4>
        <h1 className="gradient__text">Head Office</h1>
        <h4>Muthangari Road, Lavington, Nairobi.</h4> <br />
        <h1 className="gradient__text">Call Us</h1>
        <h4>+254 790 546287</h4> <br />
        <h1 className="gradient__text">Email</h1>
        <h4><a href="mailto:info@suretymedia.com">info@suretymedia.com</a></h4>
      </div>

      <div className="gpt3__form-container">
        <div className="gpt3__possibility-content">
          <h4>Get in touch with us!</h4>
          <h1 className="gradient__text">We Are Ready to <br /> Help you Grow.</h1>
        </div>

        <form ref={formRef} onSubmit={handleSubmit} className="gpt3__contact-form" id="contact">
          <div className="gpt3__form-group">
            <label htmlFor="name" className="gpt3__label">
              Name:
            </label>
            <input type="text" id="name" name="name" value={form.name} onChange={handleChange} className="gpt3__input" />
          </div>
          <div className="gpt3__form-group">
            <label htmlFor="email" className="gpt3__label">
              Email:
            </label>
            <input type="email" id="email" name="email" value={form.email} onChange={handleChange} className="gpt3__input" />
          </div>
          <div className="gpt3__form-group">
            <label htmlFor="message" className="gpt3__label">
              Message:
            </label>
            <textarea id="message" name="message" value={form.message} onChange={handleChange} className="gpt3__textarea" />
          </div>
          <button type="submit" disabled={loading} className="gpt3__button">
            {loading ? 'Sending...' : 'Submit'}
          </button>
        </form>
      </div>

    </div>
  );
};

export default Contact;
