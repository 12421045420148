/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import logo from '../../assets/surety_logo.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleScroll = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setToggleMenu(false);
      window.location.hash = sectionId;
    }
  };

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="Surety Logo" />
        </div>
        <div className="gpt3__navbar-links_container">
          <p tabIndex={0} onClick={() => handleScroll('#home')}>Home</p>
          <p tabIndex={0} onClick={() => handleScroll('about_us')}>Who we are?</p>
          <p tabIndex={0} onClick={() => handleScroll('features')}>Our Services</p>
          <p tabIndex={0} onClick={() => handleScroll('team')}>The Team</p>
          <p tabIndex={0} onClick={() => handleScroll('blog')}>Blog</p>
          <p tabIndex={0} onClick={() => handleScroll('brands')}>Our Portfolio</p>
          <Link to="/gallery">
            <p>Gallery</p>
          </Link>
          <p tabIndex={0} onClick={() => handleScroll('contact')}>Contact Us</p>
        </div>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p tabIndex={0} onClick={() => handleScroll('#home')}>Home</p>
              <p tabIndex={0} onClick={() => handleScroll('about_us')}>Who we are?</p>
              <p tabIndex={0} onClick={() => handleScroll('features')}>Our Services</p>
              <p tabIndex={0} onClick={() => handleScroll('team')}>The Team</p>
              <p tabIndex={0} onClick={() => handleScroll('blog')}>Blog</p>
              <p tabIndex={0} onClick={() => handleScroll('brands')}>Our Portfolio</p>
              <Link to="/gallery">
                <p>Gallery</p>
              </Link>
              <p tabIndex={0} onClick={() => handleScroll('contact')}>Contact Us</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
