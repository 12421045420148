import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Branding',
    text: 'At Surety PR & Media, we believe that branding is an art form. That is why we have assembled a team of branding specialists who are passionate about creating memorable brand identities that leave a lasting impression on your customers.',
  },
  {
    title: 'Marketing',
    text: 'At Surety PR & Media, we believe that effective marketing is all about telling a compelling story. That is why we work with you to craft a messaging strategy that resonates with your customers and sets your business apart from the competition.',
  },
  {
    title: 'Media Relations',
    text: 'From developing media pitches and press releases to coordinating interviews and managing crisis communications, our media relations services can help you navigate the complex world of modern media with ease.',
  },
  {
    title: 'Corporate Communications',
    text: 'From crisis communication and media relations to internal communication and stakeholder engagement, our corporate communication services can help your business navigate any communication challenge with confidence.',
  },
  {
    title: 'Experiential',
    text: 'We believe that every event is unique, which is why we take a customized approach to event planning and management. Our team works with you to understand your goals and objectives, ensuring that your event is tailored to meet your specific needs.',
  },
  {
    title: 'Digital Communications',
    text: 'Our digital communication services are designed to help you connect with your customers in meaningful ways. Whether you need help with social media, content marketing, or email campaigns, we can help you reach your target audience and build lasting relationships with your customers.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">We devote ourselves to giving our clients the full scope of Publicity and Public relations.</h1>
      <p>Our wide range of Services.</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
