import React from 'react';
import './projects.css';

const ProjectModal = ({ project, closeModal }) => {
  const handleCloseModal = (e) => {
    if (e.target.classList.contains('project-modal')) {
      closeModal();
    }
  };

  return (
    <div className="project-modal" onClick={handleCloseModal}>
      <div className="modal-content">
        {project ? (
          <>
            <div className="video-container">
              <iframe
                title="Project Video"
                src={project.videoUrl}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                width="100%"
                height="100%"
              />
            </div>
            <div className="project-details">
              <h1 className="gradient__text">{project.title || 'No Title'}</h1>
              <p>{project.description || 'No Description'}</p>
              <p>{project.additionalParagraph || 'No Additional Information'}</p>
            </div>
          </>
        ) : (
          <p>No project details available</p>
        )}
        <div className="gpt3__closemodal-btn">
          <button type="button" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;
